import { useState, ChangeEvent, SyntheticEvent, useEffect } from 'react';
import styled from 'styled-components';

import BackgroundImage from './../assets/images/cs-background-blue.jpg';
import CSLogo from './../assets/images/cs-logo-red-sky.svg';

import colors from '../app/colors';

export type FormInputData = {
  code: string;
  teamName: string;
};

type CodeInputScreenProps = {
  onSubmitData: (data: FormInputData) => void;
  initialData: {
    code: string;
    teamName: string;
  };
};

function CodeInputScreen({ onSubmitData, initialData }: CodeInputScreenProps) {
  const [code, setCode] = useState<string>(initialData.code);

  const [teamName, setTeamName] = useState<string>(initialData.teamName);

  const [disabled, setDisabled] = useState<boolean>(true);

  function onSubmit(e: SyntheticEvent) {
    e.preventDefault();
    onSubmitData({ code, teamName });
    setCode('');
  }

  function onCodeUpdated(event: ChangeEvent<HTMLInputElement>) {
    const sanitized = event.target.value.toUpperCase().replace(/[^A-Z]/g, '');
    setCode(sanitized);
  }

  function onTeamNameUpdated(event: ChangeEvent<HTMLInputElement>) {
    setTeamName(event.target.value);
  }

  useEffect(() => {
    setDisabled(code.length !== 5);
  }, [code]);

  return (
    <Background>
      <Logo src={CSLogo} alt="Circus Street logo" />
      <MainContainer>
        <DescriptionContainer>
          <Title>The Swipe Game</Title>
          <Description>
            <p>Hi there, and welcome to Circus Street's swipe game!</p>
            <p>
              On this group call your conversations will be analyzed by an AI to see what you as a
              group discuss as you face each decision. This is not an assessment - it's simply a way
              to gather insights on the topics you and your colleagues talk about in a fun, safe
              space for discussion.
            </p>
            <p>
              To get started, you'll need to come up with a team name. You'll also need to enter
              your Session ID, which is a five letter code found in the title of your calendar event
              - we need this for recording gameplay data so please make sure you enter it below.
            </p>
            <p>Thanks - and good luck!</p>
          </Description>
        </DescriptionContainer>
        <FormContainer onSubmit={onSubmit}>
          <InputLabel htmlFor="codeInput">
            To play the game, please enter your team name and your 5 letter meeting code:
          </InputLabel>
          <InputContainer>
            <TeamName
              id="teamName"
              type="text"
              value={teamName}
              placeholder="Team name"
              onChange={onTeamNameUpdated}
            />
            <CodeInput
              id="codeInput"
              type="text"
              value={code}
              placeholder="5 letter code"
              onChange={onCodeUpdated}
              maxLength={5}
            />
            <SubmitButton type="submit" disabled={disabled}>
              Enter Code
            </SubmitButton>
          </InputContainer>
        </FormContainer>
      </MainContainer>
    </Background>
  );
}

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundImage});
  background-size: cover;
`;

const MainContainer = styled.div`
  flex: grow;
`;

const Logo = styled.img`
  width: 180px;
  padding: 40px 0;
`;

const DescriptionContainer = styled.div`
  width: auto;
  max-width: 700px;
  padding: 40px;
  background: white;
`;

const Title = styled.h2`
  margin-top: 0;
  text-align: center;
  font-size: 26px;
`;

const Description = styled.p`
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
`;

const FormContainer = styled.form`
  margin-top: 10px;
  padding: 30px;
  background: white;
`;

const InputLabel = styled.label`
  font-family: 'Circular Medium';
  font-size: 18px;
  display: block;
  text-align: center;
`;

const InputContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CodeInput = styled.input`
  padding: 8px 10px;
  font-family: 'Circular Medium';
  font-size: 22px;
  width: 140px;
  margin-right: 10px;

  &::placeholder {
    opacity: 0.4;
    color: black;
  }
`;

const TeamName = styled.input`
  padding: 8px 10px;
  font-family: 'Circular Medium';
  font-size: 22px;
  width: 200px;
  margin-right: 10px;

  &::placeholder {
    opacity: 0.4;
    color: black;
  }
`;

const SubmitButton = styled.button`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 160px;
  font-family: 'Circular Medium';
  font-size: 18px;
  color: white;
  background: ${colors.plum};
  border: none;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export default CodeInputScreen;
