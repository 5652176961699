import { now, future } from './time';

function ttlStorage(defaultTtl = '1h') {
  function getItem(key: string) {
    const item = localStorage.getItem(key);

    // if item doesn't exist return null, same as localStorage:
    if (!item) {
      return null;
    }
    let parsed: any;
    try {
      parsed = JSON.parse(item);
    } catch (error) {
      // if cannot parse item (not serialized object), return item:
      return item;
    }

    // if value was set with ttlStorage, return value if item has not expired:
    if (parsed.hasOwnProperty('ttlStorageExpiry') && parsed.hasOwnProperty('ttlStorageValue')) {
      return now() > parsed.ttlStorageExpiry ? null : parsed.ttlStorageValue;
    }

    // if value was not set with ttlStorage, return item as localStorage would do:
    return item;
  }

  function setItem(key: string, value: any, ttl = defaultTtl) {
    localStorage.setItem(
      key,
      JSON.stringify({ ttlStorageValue: value, ttlStorageExpiry: future(ttl) })
    );
  }

  // wrapped functions:
  function key(index: number) {
    return localStorage.key(index);
  }
  function clear() {
    return localStorage.clear();
  }
  function length() {
    return localStorage.length;
  }
  function removeItem(key: string) {
    return localStorage.removeItem(key);
  }
  return { getItem, setItem, key, clear, length, removeItem };
}

export default ttlStorage;
