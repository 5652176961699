import React, { useEffect, useState } from 'react';

import { ClaimUIPackage } from './claim-ui/security';
import config from './app/config';

import { isValidCode } from './app/gameCodes';

import CodeInputScreen, { FormInputData } from './components/CodeInputScreen';
import WarningPrompt from './components/WarningPrompt';
import styled from 'styled-components';

import ttlStorage from './app/ttlStorage';

const storage = ttlStorage('1h');

// Get the version parameter from the current URL
const urlParams = new URLSearchParams(window.location.search);
const requestedContent = urlParams.get('play');

export async function getCSAuthToken(): Promise<string | undefined> {
  try {
    await ClaimUIPackage.getTokenServer(config.CS_SECURITY_URL);
    const token = ClaimUIPackage.getToken();
    if (!token) {
      throw new Error();
    }
    return token;
  } catch (error) {
    ClaimUIPackage.login(config.CS_LOGIN_URL, config.APP_URL + (requestedContent ? `?play=${requestedContent}` : ""));
  }
}

const onMessageReceived = async (event: { data: string; origin: string }) => {
  if (event.origin !== process.env.REACT_APP_SWIPE_GAME_URL) {
    console.log('not expecting a message from this origin:', event.origin);
    return;
  }

  const code = storage.getItem('gameCode') || '';

  const teamName = storage.getItem('teamName') || '';

  let data: any = {};
  try {
    data = JSON.parse(event.data);
  } catch (error) {
    console.log('Could not parse JSON object');
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SWIPE_GAME_API_URL}/gamedata`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code, teamName, data }),
    });
    const responseData = await response.json();
    console.log(responseData);
  } catch (error) {
    console.log(error);
  }
};

function App() {
  const [gameCode, setGameCode] = useState<string>(storage.getItem('gameCode') || '');

  const [teamName, setTeamName] = useState<string>(storage.getItem('teamName') || '');

  const [showWarning, setShowWarning] = useState<boolean>(false);

  const [ignoreWarning, setIgnoreWarning] = useState<boolean>(false);

  // forces CS login:
  if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
    getCSAuthToken();
  }

  useEffect(() => {
    window.addEventListener('message', onMessageReceived);
    return () => window.removeEventListener('message', onMessageReceived);
  }, []);

  // save the value from the input screen:
  function onCodeSubmitted(data: FormInputData) {
    setGameCode(data.code);
    setTeamName(data.teamName);

    storage.setItem('gameCode', data.code);
    storage.setItem('teamName', data.teamName);

    // if code is incorrect, show notification:
    setShowWarning(!isValidCode(data.code));
  }

  const style = {
    border: 'none',
    margin: 0,
    width: '100%',
    height: '100%',
  };

  const showGameScreen = isValidCode(gameCode) || ignoreWarning;

  function onWarningPromptSubmit(agree: boolean) {
    setIgnoreWarning(agree);
    setShowWarning(false);
  }

  // Append the version parameter to the iframe's URL
  const iframeUrl = process.env.REACT_APP_SWIPE_GAME_URL + (requestedContent ? `?play=${requestedContent}` : "");

  return (
    <Container>
      {showGameScreen ? (
        <iframe style={style} title="Swipe Game" src={iframeUrl} />
      ) : (
        <CodeInputScreen
          onSubmitData={onCodeSubmitted}
          initialData={{ code: gameCode, teamName }}
        />
      )}
      {showWarning && <WarningPrompt onSubmit={onWarningPromptSubmit} code={gameCode} />}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;

export default App;
