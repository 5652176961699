/**
 *
 * NOTE!
 *
 * These codes are given out to internal contacts (account management/ CSM ) to be shared with clients.
 * It's important to keep track of which codes belong to which client.
 *
 * I have done so here:
 * https://workforce-learning.atlassian.net/wiki/spaces/RD/pages/4501635248/Swipe+Game+Codes
 * Do note the potential for drift between the two lists.
 * This file is the technical source of truth as to which codes will be accepted by the login UI as valid.
 *
 */

const codes = [

  'OOFVC',
  'QCFCC',
  'EBUHD',
  'CBHHH',
  'SYPOG',
  'YXGQV',
  'WZMXD',
  'ITLQY',
  'NLXYJ',
  'QLUHV',
  'FMFTK',
  'HJHIS',
  'ABFQW',
  'UFAOJ',
  'LGGJC',
  'OBGLY',
  'OPMSM',
  'NLESG',
  'AWWBG',
  'JPETI',
  'JYLMU',
  'GLMYY',
  'ZBDJV',
  'FPNZT',
  'QBWPR',
  'NPIJR',
  'LIQYB',
  'IOMVY',
  'JOYMZ',
  'TEQFN',
  'LBBOP',
  'VLPQH',
  'MGXHC',
  'YIZPY',
  'VMIBP',
  'OVMBX',
  'NJZOX',
  'LKTDZ',
  'KXWFL',
  'ZBNVB',
  'XKBNP',
  'RGZKY',
  'SDINB',
  'BEREV',
  'IVHYV',
  'XWLST',
  'CBVDA',
  'RXZPD',
  'MDBTB',
  'RHTBK',
  'RAASW',
  'TTYJN',
  'FVVWX',
  'KGYLF',
  'KGPOX',
  'RMKJY',
  'VIYJZ',
  'EZLWG',
  'JIINS',
  'GBWLO',
  'GJLAQ',
  'HEMCH',
  'CDWGV',
  'RVZTK',
  'YMFMF',
  'KSREJ',
  'MJXAC',
  'XFVEE',
  'FFJDW',
  'SIKRB',
  'IKSDI',
  'SWEKV',
  'QWSUS',
  'LBMLP',
  'UQYST',
  'KBOJC',
  'ZMSXU',
  'JTVDZ',
  'MDAKC',
  'LQFTC',
  'DVBCF',
  'FRUYU',
  'PZXOO',
  'LXSVU',
  'PSFPJ',
  'GGZXM',
  'NIPXB',
  'JDERQ',
  'INMEM',
  'VFBSE',
  'LPDZW',
  'DTLTC',
  'ZOMAL',
  'LLAHG',
  'CGCIB',
  'QXSOF',
  'TMNOO',
  'EOSHP',
  'XBFBI',
  'JTGNQ',

  // New batch generated 2024-07-15
  // These were not checked for duplicates with above list.
  // https://www.gigacalculator.com/randomizers/random-alphanumeric-generator.php
  'WTEAH',
  'TISNV',
  'RSFNR',
  'XZZWQ',
  'HUQIP',
  'GMBGF',
  'EQNAE',
  'NCXID',
  'AXOOD',
  'NVVYN',
  'OFGBM',
  'DDVQX',
  'XKRJS',
  'GLUUN',
  'EIAYL',
  'QIMBI',
  'VKPIW',
  'PYTIW',
  'VBKYE',
  'BLZTS',
  'EJTOB',
  'PPKED',
  'GMJFZ',
  'UOQIR',
  'ZOBKV',
  'ZIJGO',
  'PMFFN',
  'FJMMQ',
  'PPOLP',
  'RDLCF',
  'MOLPK',
  'DKJSC',
  'JVRPJ',
  'QQRCI',
  'SGCKH',
  'LTDBB',
  'XERTZ',
  'KRXCU',
  'ELLOZ',
  'XVQWW',
  'THDNL',
  'FFDRF',
  'RGHZB',
  'VQVZZ',
  'HVVOM',
  'MOTHG',
  'JPMVF',
  'UXQZS',

];

export function isValidCode(inputCode: string) {
  return !!codes.find((code) => code === inputCode.toUpperCase());
}
