import styled from 'styled-components';

import colors from '../app/colors';

type WarningPromptProps = {
  onSubmit: (agree: boolean) => void;
  code: string;
};

const WarningPrompt = ({ onSubmit, code }: WarningPromptProps) => {
  return (
    <Background>
      <Container>
        <Title>Warning!</Title>
        <Description>
          The code you entered ({code}) looks invalid and you could lose the meeting recording. Are
          you sure you want to proceed?
        </Description>
        <Buttons>
          <SecondaryButton onClick={() => onSubmit(true)}>Yes! Go ahead anyway</SecondaryButton>
          <PrimaryButton onClick={() => onSubmit(false)}>No, I'll try a new code</PrimaryButton>
        </Buttons>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Container = styled.div`
  width: 500px;
  background: white;
  padding: 30px;
`;

const Title = styled.h2`
  text-align: center;
`;

const Description = styled.p`
  text-align: center;
`;

const Buttons = styled.div`
  text-align: center;
`;

const SecondaryButton = styled.button`
  background: white;
  color: black;
  padding: 8px 20px;
  font-family: 'Circular Medium';
  font-size: 18px;
  border: 1px solid ${colors.plum};
  margin-right: 20px;
`;

const PrimaryButton = styled.button`
  background: ${colors.plum};
  color: white;
  padding: 8px 20px;
  font-family: 'Circular Medium';
  font-size: 18px;
  border: 1px solid ${colors.plum};
`;

export default WarningPrompt;
