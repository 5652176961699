const pipe = (...ops: any[]) => ops.reduce((a: any, b: any) => (arg: any) => b(a(arg)));

// map time string key to values:
export const milliMap: { [key: string]: number } = {
  s: 1000, // 1s = 1000ms
  m: 60000, // 60 * 1000
  h: 3600000, // 60 * 60 * 1000
  d: 86400000, // 24 * 60 * 60 * 1000
  w: 604800000, // 7 * 24 * 60 * 60 * 1000
  mo: 2628000000, // (365 / 12) * 24 * 60 * 60 * 1000
  y: 31536000000, // 365 * 24 * 60 * 60 * 1000
};

const getMatchesFromTimeString = (timeStr: string) => timeStr.match(/^(\d+)(s|m|h|d|w|mo|y)$/);

const getParamsFromMatches = (matches: any[] | null) =>
  matches ? [matches[1], matches[2]] : undefined;

// return number of milliseconds from time string params
const getMillisFromParams = ([n, l]: [string, string]) => parseInt(n) * milliMap[l] || 0;

// get present time in UTC format:
export const now = () => new Date().getTime();

// get time in millisecods from time string:
export const ms = pipe(getMatchesFromTimeString, getParamsFromMatches, getMillisFromParams);

// get future time in UTC format from time string:
export const future = pipe(ms, (ms: number) => ms + now());

// get past time in UTC format from time string:
export const past = pipe(ms, (ms: number) => now() - ms);
