import { createGlobalStyle } from 'styled-components';

import CircularBold from './../assets/fonts/CircularXXWeb-Bold.woff';
import CircularBold2 from './../assets/fonts/CircularXXWeb-Bold.woff2';

import CircularMedium from './../assets/fonts/CircularXXWeb-Medium.woff';
import CircularMedium2 from './../assets/fonts/CircularXXWeb-Medium.woff2';

import CircularBook from './../assets/fonts/CircularXXWeb-Book.woff';
import CircularBook2 from './../assets/fonts/CircularXXWeb-Book.woff2';

import DMSansRegular from './../assets/fonts/dmsans-regular-webfont.woff';
import DMSansRegular2 from './../assets/fonts/dmsans-regular-webfont.woff2';

export const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Circular Medium';
    src: local('Circular Medium'), local('CircularMedium'),
    url(${CircularMedium2}) format('woff2'),
    url(${CircularMedium}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circular Bold';
    src: local('Circular Bold'), local('CircularBold'),
    url(${CircularBold2}) format('woff2'),
    url(${CircularBold}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circular Book';
    src: local('Circular Book'), local('CircularBook'),
    url(${CircularBook2}) format('woff2'),
    url(${CircularBook}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'DMSans Regular';
    src: local('DMSans Regular'), local('DMSansRegular'),
    url(${DMSansRegular2}) format('woff2'),
    url(${DMSansRegular}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'DMSans Regular';
    line-height: 1.5rem;
    margin: 0;
  }
  h1{
    font-family: 'Circular Bold';
  }
  h2{
    font-family: 'Circular Bold';
  }
  h3{
    font-family: 'Circular Bold';
  }
  #root{
    height: 100vh;
  }    
`;
